import { SetStateAction } from 'react';
import { SiteDetail, SitePaginationState } from 'api/site';
import { useAcceptInviteLoggedIn } from 'api/auth';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Link, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { useNavigate } from 'react-router-dom';
import { components } from 'openapi-types';

type Sites = Pick<components['schemas']['Site'], 'status' | 'id' | 'domain' | 'label'>[];

type Props = {
  readonly isWpSiteControl: boolean;
  readonly sites: Sites;
  readonly pagination: SitePaginationState;
  readonly setPagination: (value: SetStateAction<SitePaginationState>) => void;
  readonly opositeSortOrder: 'asc' | 'desc';
  readonly filteredSitesList: Sites;
};

export const PendingSitesList = ({
  isWpSiteControl,
  sites,
  pagination,
  setPagination,
  opositeSortOrder,
  filteredSitesList,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync } = useAcceptInviteLoggedIn();

  if (!isWpSiteControl || !sites.some(site => site.status?.toLowerCase() === 'invited')) {
    return null;
  }

  return (
    <Table aria-label="Pending Sites Table" sx={{ marginBottom: 4 }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Box component="span">
              <Link
                onClick={() => {
                  setPagination({
                    ...pagination,
                    sortAttr: 'domain',
                    sortOrder: pagination.sortAttr === 'domain' ? opositeSortOrder : 'asc',
                  });
                }}
              >
                {t('domain')}{' '}
                {pagination.sortAttr === 'domain' ? (
                  <Box display="inline-block" marginLeft={1}>
                    <FontAwesomeIcon
                      icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                    />
                  </Box>
                ) : null}
              </Link>
            </Box>
          </TableCell>
          <TableCell>
            <Link
              onClick={() => {
                setPagination({
                  ...pagination,
                  sortAttr: 'label',
                  sortOrder: pagination.sortAttr === 'label' ? opositeSortOrder : 'asc',
                });
              }}
            >
              {t('label')}
              {pagination.sortAttr === 'label' ? (
                <Box display="inline-block" marginLeft={1}>
                  <FontAwesomeIcon
                    icon={pagination.sortOrder === 'asc' ? faAngleUp : faAngleDown}
                  />
                </Box>
              ) : null}
            </Link>
          </TableCell>
          <TableCell>{t('invite_status')}</TableCell>
          <TableCell width={150}>{t('action')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {pagination.filter !== '' && filteredSitesList.length === 0 ? (
          <NoResultsFound colSpan={4} />
        ) : (
          sites
            .filter(site => site.status?.toLowerCase() === 'invited')
            .map(site => (
              <TableRow key={site.id}>
                <TableCell>{site.domain}</TableCell>
                <TableCell>{site.label}</TableCell>
                <TableCell>{site.status}</TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    onClick={async () => {
                      await mutateAsync({ siteId: site.id! });
                      navigate(`/sites/${site.id}`);
                    }}
                  >
                    {t('accept_invite')}
                  </Button>
                </TableCell>
              </TableRow>
            ))
        )}
      </TableBody>
    </Table>
  );
};
